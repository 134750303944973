import React from "react"
import SEO from "../components/layout/seo"

import Layout from "../components/layout/layout"
import BlogMain from "../components/blog-main/blog-main"

const Blog = () => (
  <Layout home={false}>
    <SEO title="Contact Us" />
    <BlogMain />
  </Layout>
)

export default Blog
