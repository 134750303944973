import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"

import "./blog-main.scss"

const BlogMain = () => {
  return (
    <>
      <div className="blog-banner">
        <div className="blog-banner-banner"></div>
        <div className="blog-banner-content">
          <div className="blog-banner-content-heading">
            <b>HSL Blog</b>
          </div>
          <div className="blog-banner-content-content">
            <b>Let's talk energy.</b>
          </div>
        </div>
      </div>
      <StaticQuery
        query={graphql`
          query BlogPostQuery {
            allMarkdownRemark {
              edges {
                node {
                  html
                  frontmatter {
                    path
                    title
                    date
                    author
                  }
                }
              }
            }
          }
        `}
        render={data =>
          data.allMarkdownRemark.edges.map(edge => (
            <BlogCard node={edge.node} />
          ))
        }
      />
    </>
  )
}

export default BlogMain

const BlogCard = props => {
  return (
    <>
      <div className="blog-card">
        <div className="blog-tile">
          <div className="blog-title">
            <b>{props.node.frontmatter.title}</b>
          </div>
          <div className="blog-author">by {props.node.frontmatter.author}</div>
        </div>
        <div className="blog-detail">
          <div className="blog-date">{props.node.frontmatter.date}</div>
          <Link className="blog-link" to={props.node.frontmatter.path}>
            Know More
          </Link>
        </div>
      </div>
    </>
  )
}
